import React from 'react';
import { SingleCaseStudy } from 'views/single-case-study';
import { useCryptocurrencyExchange } from 'views/single-case-study/hooks/useCryptocurrencyExchange';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/case-studies/cryptocurrency-exchange-platform.json';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const CryptocurrencyExchangePlatformPage = (props) => {
    const { location } = props;
    const { data } = useCryptocurrencyExchange();

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <SingleCaseStudy data={data} />
        </Layout>
    );
};

CryptocurrencyExchangePlatformPage.propTypes = {
    location: object.isRequired,
};

export default CryptocurrencyExchangePlatformPage;

export const Head = () => (
    <SEO tags={TAGS.CASE_STUDIES_CRYPTOCURRENCY_EXCHANGE_PLATFORM} />
);
