import { graphql, useStaticQuery } from 'gatsby';
import ReactJsIcon from 'svgs/single-case-study/react-js.svg';
import UxUiDesign from 'svgs/single-case-study/ux-ui-design.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'cryptocurrencyExchangePlatform',
    brandColor: '--cryptocurrency-exchange-case-study',
    brandColorGradient: {
        start: '--cryptocurrency-exchange-gradient-start',
        stop: '--cryptocurrency-exchange-case-study',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.reactJs',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.reactNative',
            Icon: ReactJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiDesign,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 1,
        about: 1,
        challenge: 1,
        mainGoals: 2,
        solutions: 2,
        summary: 2,
    },
    caseStudies: [
        'moneyTransferApplication',
        'nomisSolutions',
        'salesPredictiveAlgorithm',
    ],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

export const useCryptocurrencyExchange = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/cryptocurrency-exchange/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
